import React from 'react';
import PropTypes from 'prop-types';
import { Link, Text, Title } from '../../atoms';

const ErrorDisplay = ({ NavLinkComponent }: { NavLinkComponent: any }) => (
  <>
    <Title>Something went wrong</Title>
    <Text>
      We&rsquo;re onto it. Maybe you&rsquo;d like to go{' '}
      <Link NavComponent={NavLinkComponent} to="/" underline color="brand.red">
        home
      </Link>
      ?
    </Text>
  </>
);

ErrorDisplay.propTypes = {
  NavLinkComponent: PropTypes.any.isRequired,
};
ErrorDisplay.defaultProps = {};

export default ErrorDisplay;
