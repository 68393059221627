import React from 'react';
// import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Box from '../../atoms/Box';
import bird from './bird.svg';

const changeBg = keyframes`
  0%   {background: #ff685d;}
  33%  {background: #ffb45d;}
  66%  {background: #265682;}
  100% {background: #ff685d;}
`;

const Loader = styled(Box)`
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  animation: linear ${changeBg} 1.5s infinite;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 42px;
    height: 42px;
    background: url('${bird}');
  }
`;

const PageLoading = (props) => (
  <Box height="100%" display="flex" {...props}>
    <Loader m="auto" />
  </Box>
);

// PageLoading.propTypes = {
//   mt: PropTypes.string,
// };

export default PageLoading;
