import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import media from 'styled-media-query';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import Box from '../../atoms/Box';
import Title from '../../atoms/Title';
import Text from '../../atoms/Text';
import Link from '../../atoms/Link';
import Icon from '../../atoms/Icon';
import Divider from '../../atoms/Divider';

const ImageWrap = styled(Box)`
  display: inline-block;
  vertical-align: top;
  position: relative;
  max-width: 272px;
  width: 100%;
  flex-shrink: 0;
  align-self: flex-start;
`;
const Linkedin = styled(Box)`
  position: absolute;
`;
const StyledImg = styled(Box)`
  display: block;

  .gatsby-image-wrapper::after,
  &::after {
    content: '';
    display: block;
    padding-bottom: 132.35%;
  }
`;

const Holder = styled(Box)`
  position: absolute;
  top: 21px;
  right: -21px;
  width: 21px;
  bottom: 0;
  background: linear-gradient(180deg, #f6f8f9 27.52%, #fff 100%);
  ${media.lessThan(bp.s)`
    top: 11px;
    right: -11px;
    width: 11px;
  `};
`;

const MemberCard = ({
  data,
  image,
  name,
  post,
  linkedin,
  to,
  children,
  NavLinkComponent,
  ...rest
}: {
  data?: any;
  image: any;
  name: string;
  post?: string;
  linkedin?: string;
  to?: any;
  children: any;
  NavLinkComponent: any;
}) => {
  const imgStyles = {
    width: '100%',
    maxWidth: '272px',
    position: 'absolute',
    height: null,
  };
  const MemberImg = () => (
    <StyledImg bg="grayscale.300">
      {data && <Img fixed={data} style={imgStyles} />}
    </StyledImg>
  );
  return (
    <Box maxWidth={272} mx="auto" {...rest}>
      <Box display={['block', 'flex']}>
        <ImageWrap mb="24px">
          {to && !children ? (
            <Link NavComponent={NavLinkComponent} to={to}>
              <MemberImg />
            </Link>
          ) : (
            <MemberImg />
          )}
          {linkedin && (
            <Linkedin bottom={[0, '-24px']} right={[0, '24px']} colors="card.s">
              <Link href={linkedin}>
                <Icon name="linkedin" />
              </Link>
            </Linkedin>
          )}
          <Holder />
        </ImageWrap>
        {children && (
          <Box flex={1} ml={[0, '70px']}>
            <Title as="h3" size="h6" mb={['24px', '32px']}>
              {post}
            </Title>
            <Divider mb={['24px', '32px']} />
            {children}
          </Box>
        )}
      </Box>
      {!children && (
        <>
          <Title as="h3" size="h6" mb="8px">
            {to ? (
              <Link NavComponent={NavLinkComponent} to={to}>
                {name}
              </Link>
            ) : (
              <>{name}</>
            )}
          </Title>
          <Text m={0}>{post}</Text>
        </>
      )}
    </Box>
  );
};

MemberCard.propTypes = {
  image: PropTypes.string,
  data: PropTypes.object,
  name: PropTypes.string,
  post: PropTypes.string,
  linkedin: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
  NavLinkComponent: PropTypes.any.isRequired,
};
MemberCard.defaultProps = {
  image: null,
  data: null,
  name: null,
  post: null,
  linkedin: null,
  to: null,
  children: null,
};

export default MemberCard;
