import React from 'react';
import PropTypes from 'prop-types';
import { capitalise } from '@firsttable/functions';
import { Box } from '../../atoms';

type AvailabilitySessionButtonType = {
  label: string;
  session: string;
  setSession: (s: string) => void;
  displayLabel?: string;
  showActive?: boolean;
};
const AvailabilitySessionButton = ({
  label,
  session,
  setSession,
  displayLabel,
  showActive,
}: AvailabilitySessionButtonType) => {
  const isActive = label === session || showActive;
  return (
    <Box
      mr="25px"
      color={isActive ? 'brand.red' : ''}
      role="button"
      onClick={() => setSession(label)}
    >
      <Box as="span" borderBottom={isActive ? '1px solid' : null}>
        {displayLabel || capitalise(label)}
      </Box>
    </Box>
  );
};

AvailabilitySessionButton.propTypes = {
  label: PropTypes.string,
  displayLabel: PropTypes.string,
  showActive: PropTypes.bool,
  session: PropTypes.string.isRequired,
  setSession: PropTypes.func.isRequired,
};
AvailabilitySessionButton.defaultProps = {
  label: 'Book',
  displayLabel: null,
  showActive: false,
};

export default AvailabilitySessionButton;
