import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import Box from '../../atoms/Box';
import Checkbox from '../InputCheckbox';

const SubscriptionList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  ${media.greaterThan(bp.s)`
    column-count: 2;
    column-gap: 30px;
  `};
`;

// Checkbox group
class ColumnCheckboxGroup extends Component {
  handleChange = (event) => {
    const {
      onChange,
      field: {
        name,
      },
      form: { values },
    } = this.props;
    const target = event.currentTarget;
    const valueArray = [...values[name]] || [];

    if (target.checked) {
      valueArray.push(target.value);
    } else {
      valueArray.splice(valueArray.indexOf(target.value), 1);
    }

    onChange(name, valueArray);
  };

  handleBlur = () => {
    const { onBlur, id } = this.props;
    // take care of touched
    onBlur(id, true);
  };

  render() {
    const {
      field: {
        name,
      },
      form: { values },
      options,
    } = this.props;

    return (
      <SubscriptionList>
        {options.map((opt, i) => {
          const id = `${name}-${opt.inputValue}`;
          const value = `${opt.inputValue}`;
          return (
          // eslint-disable-next-line react/no-array-index-key
            <Box as="li" key={i}>
              <Checkbox
                id={id}
                name={name}
                label={opt.inputLabel}
                value={value}
                selected={values[name].includes(value)}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </Box>
          );
        })}
      </SubscriptionList>
    );
  }
}

ColumnCheckboxGroup.propTypes = {
  field: PropTypes.shape({
    values: PropTypes.array,
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }).isRequired,
  options: PropTypes.array,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
};

ColumnCheckboxGroup.defaultProps = {
  options: [],
  id: '',
};

export default ColumnCheckboxGroup;
