import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Flex, Icon } from '../../atoms';

const StyledBtn = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 50px;
  background: ${themeGet('colors.brand.blue')};
`;

const MobileFilterBtn = ({ icon, kind, ...props }) => {
  const addProps = () => {
    switch (kind) {
      case 'left':
        return {
          borderRadius: '50px 0 0 50px',
          pl: '3px',
          width: '50%',
          borderRight: '1px solid #38638a',
        };
      case 'right':
        return {
          borderRadius: '0 50px 50px 0',
          pr: '3px',
          width: '50%',
        };
      default:
        return {
          borderRadius: '50px',
          width: '100%',
        };
    }
  };

  return (
    <StyledBtn {...addProps()} {...props} role="button">
      <Icon name={icon} color="white" />
    </StyledBtn>
  );
};

MobileFilterBtn.propTypes = {
  icon: PropTypes.string,
  kind: PropTypes.string,
};

MobileFilterBtn.defaultProps = {
  icon: 'm-filter',
};

export default MobileFilterBtn;
