import React, { CSSProperties } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DisplayProps, PositionProps } from 'styled-system';
import { Flex } from '../../atoms';

const StyledMobileFilter = styled(Flex)`
  z-index: 1;
  bottom: 24px;
  width: 120px;
  margin: ${({ position }) =>
    position === 'sticky' ? '-30px auto 25px' : '0 0 0 -60px'};
  left: ${({ position }) => (position === 'sticky' ? null : '50%')};
  transition: 0.2s ease-out;
  will-change: opacity, visibility;
`;

const MobileFilter = (
  props: PositionProps &
    DisplayProps & { children: React.ReactNode; style?: CSSProperties }
) => <StyledMobileFilter {...props} />;

MobileFilter.propTypes = {
  position: PropTypes.string,
};
MobileFilter.defaultProps = {
  position: 'absolute',
};

export default MobileFilter;
