import React from 'react';
import PropTypes from 'prop-types';
import { themeGet } from '@firsttable/web-theme';
import Title from '../../atoms/Title/Title';
import Box from '../../atoms/Box/Box';

const FAQ = ({ title, body }) => (
  <Box p={['xs', 'xxl']} borderTop={[null, `1px solid ${themeGet('colors.grayscale.300')}`]}>
    <Title size="h5">{title}</Title>
    {body}
  </Box>
);

FAQ.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired,
};
FAQ.defaultProps = {};

export default FAQ;
