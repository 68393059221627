import React, { useState } from 'react';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { navigate } from 'gatsby';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { ContactCTA } from '@firsttable/web-components/molecules';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import { errorMessage, scrollTo } from '@firsttable/functions';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import { withAlert } from 'react-alert';
import { Text, Box, Button, Link } from '@firsttable/web-components/atoms';
import { BaseFormProps } from '@firsttable/types';
import { withAuth, withLocation } from '../../hocs';
import TwoColumnLayout from '../../layouts/TwoColumnLayout';
import NavButton from '../../components/atoms/NavButton';
import NavLink from '../../components/atoms/NavLink';

const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword(
    $password: String!
    $token: String!
    $member: Int!
  ) {
    updateUserPassword(password: $password, token: $token, member: $member) {
      token
      member {
        id
        firstName
        email
        surname
        avatarURL
        termsAndPrivacy
        contactPermission
        city {
          id
          menuTitle
          slug
        }
      }
    }
  }
`;

const ChangePasswordForm = ({
  handleSubmit,
  isSubmitting,
  status,
  isLoggedIn,
  redirecting,
  search,
  ...props
}: BaseFormProps & {
  isLoggedIn: () => boolean;
  redirecting: boolean;
  search: any;
}) => {
  const { m, t } = search;
  const userLoggedIn = isLoggedIn();
  if (userLoggedIn && !redirecting) {
    return (
      <>
        <Text>
          You're currently logged in. If you came here to change your password,{' '}
          please log out to proceed.
        </Text>
        <Text>
          Or{' '}
          <Link
            to="/profile/"
            NavComponent={NavLink}
            underline
            color="brand.red"
          >
            click here
          </Link>{' '}
          to view your account and update your password.
        </Text>
      </>
    );
  }
  if (!m || !t) {
    return (
      <Text>
        Please use a valid password reset link to change your password. You can
        request one{' '}
        <Link
          to="/auth/forgot-password/"
          NavComponent={NavLink}
          underline
          color="brand.red"
        >
          here
        </Link>
      </Text>
    );
  }
  return (
    <FormLayout
      handleSubmit={handleSubmit}
      status={status}
      isSubmitting={isSubmitting}
      {...props}
    >
      {redirecting && (
        <Text>Please wait while you're logged in and redirected.</Text>
      )}
      <Field
        component={FormField}
        mb={['15px', '20px']}
        id="password"
        name="password"
        label="Password"
        size="l"
        type="password"
      />
      <Field
        component={FormField}
        mb={['15px', '20px']}
        id="confirm"
        name="confirm"
        label="Confirm password"
        size="l"
        type="password"
      />
      <Box mb="20px">
        <Button size="l" wide kind="cta" type="submit" isLoading={isSubmitting}>
          Update password
        </Button>
      </Box>
    </FormLayout>
  );
};

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  redirecting: PropTypes.bool,
  search: PropTypes.shape({}),
};

ChangePasswordForm.defaultProps = {
  isSubmitting: false,
  status: {},
};

type FormValues = {
  password: string;
  confirm: string;
};

type ChangePasswordProps = {
  updateUserPassword: (options: {
    variables: {
      password: string;
      token: string;
      member: number;
    };
  }) => { data: any; error: any };
  search: any;
  setUser: (options: any) => void;
  setRedirecting: (redirecting: boolean) => void;
};

const withRedirectStatus = (ComponentToWrap: any) => {
  const WithRedirectStatus = (props: any) => {
    const [redirecting, setRedirecting] = useState<boolean>(false);
    return (
      <ComponentToWrap
        redirecting={redirecting}
        setRedirecting={setRedirecting}
        {...props}
      />
    );
  };
  WithRedirectStatus.displayName = 'withRedirectStatus';
  return WithRedirectStatus;
};

const ConnectedChangePasswordForm = compose(
  withAlert(),
  withRedirectStatus,
  withAuth,
  withLocation,
  graphql(UPDATE_USER_PASSWORD, { name: 'updateUserPassword' }),
  withFormik<ChangePasswordProps, FormValues>({
    mapPropsToValues: () => ({ password: '', confirm: '' }),
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Password is required'),
      confirm: Yup.string()
        // @ts-ignore
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Password confirm is required'),
    }),
    handleSubmit: async (
      values,
      {
        props: { updateUserPassword, search, setUser, setRedirecting },
        setSubmitting,
        setStatus,
        resetForm,
      },
    ) => {
      const { m, t } = search;
      if (!m || !t) {
        setStatus({ message: 'Invalid password reset', type: 'success' });
        setSubmitting(false);
        scrollTo('form-alert', -20);
        return;
      }
      try {
        const { data, error } = await updateUserPassword({
          variables: {
            password: values.password,
            token: t,
            member: +m,
          },
        });
        if (data?.updateUserPassword?.member) {
          setRedirecting(true);
          setUser({
            ...data.updateUserPassword.member,
            token: data.updateUserPassword.token,
            isLoggedIn: true,
          });
          resetForm();
          setStatus({
            message: 'Successfully updated password',
            type: 'success',
          });
          setTimeout(() => navigate('/'), 3000);
        }
        if (error) {
          setStatus({ message: errorMessage(error), type: 'danger' });
        }
        scrollTo('form-alert', -20);
      } catch (e) {
        setStatus({ message: errorMessage(e), type: 'danger' });
        setSubmitting(false);
        scrollTo('form-alert', -20);
      }
    },
  }),
)(ChangePasswordForm);

const LostPassword = () => (
  <TwoColumnLayout
    title="Change your password"
    col1={<ConnectedChangePasswordForm />}
    col2={<ContactCTA NavButtonComponent={NavButton} />}
  />
);

LostPassword.propTypes = {};
LostPassword.defaultProps = {};

export default LostPassword;
