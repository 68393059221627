import React from 'react';
import PropTypes from 'prop-types';
import { currencyFormat } from '@firsttable/functions';
import { PromoCode } from '@firsttable/types';
import Text from '../../atoms/Text';
import BodyTitle from '../../atoms/BodyTitle';

const PromoTitle = ({ data }: { data: { PromoCode?: PromoCode } }) => (
  <>
    {data && data.PromoCode && (
      <BodyTitle as="h3">
        Sign up now to claim{' '}
        {currencyFormat(
          data.PromoCode.freeCredit || 0,
          data.PromoCode.freeCreditCurrency || ''
        )}{' '}
        free credit
        {data.PromoCode.name && <> from {data.PromoCode.name}</>}!
      </BodyTitle>
    )}
    {/* eslint-disable-next-line max-len */}
    <Text>
      First Table is a website that allows you to get 50% off the first table of
      the night at participating restaurants!
    </Text>
  </>
);

PromoTitle.defaultProps = {
  data: {},
};

PromoTitle.propTypes = {
  data: PropTypes.object,
};

export default PromoTitle;
